<template>
  <div>
    <div class="flex w-full items-center justify-between">
      <p
        v-if="mappedList.length === 0 && this.responsible === ''"
        class="text-grey-dark-1 font-medium text-mdlh"
      >
        No invites added yet.
      </p>
      <InputField
        v-else
        label="Filter by"
        placeholder="Search by responsible"
        leftIcon
        :onChange="(value) => (filter = value)"
        :defaultValue="filter"
        class="w-inputField"
      />
      <!-- Add actual history in the future -->
      <div class="flex">
        <Button
          v-if="mappedList.length !== 0"
          :disabled="isUnlimited"
          text="Remove Invites"
          type="secondary"
          size="medium"
          :iconLeft="minus"
          @click.native="removeInvites"
          class="mr-2"
        />
        <Button
          text="Add Invites"
          type="primary"
          :iconLeft="add"
          :disabled="isUnlimited"
          size="medium"
          @click.native="addInvites"
          data-testid="add-invites-button"
        />
      </div>
    </div>
    <div v-if="mappedList.length !== 0">
      <p class="my-2 text-grey-dark-2 text-sm-2">
        Showing {{ mappedList.length }} out of {{ totalCount }} Entries
      </p>
      <div>
        <div class="grid grid-cols-6 items-center bg-grey-light-3 py-0.4">
          <TableHeader
            hasSorting
            @click.native="sort()"
            :arrowDirection="this.sorting.date === -1"
            >DATE</TableHeader
          >
          <TableHeader>NO. OF INVITES</TableHeader>
          <TableHeader>ACTIVITY</TableHeader>
          <TableHeader>INVITES REMAINING</TableHeader>
          <TableHeader>RESPONSIBLE NAME</TableHeader>
          <TableHeader>RESPONSIBLE ROLE</TableHeader>
        </div>

        <VirtualList
          style="min-height: 200px; max-height: 364px; overflow-y: auto"
          :data-key="'uuid'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          data-testid="invites-history-table"
          @tobottom="triggerFetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import add from "@/assets/img/icons/add.svg";
import minus from "@/assets/img/icons/minus.svg";
import TableHeader from "@/components/Table/TableHeader.vue";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import { mapActions, mapState } from "vuex";
import debounce from "lodash/debounce";

export default {
  name: "InvitesHistoryTable",
  components: { TableHeader, VirtualList },
  computed: {
    ...mapState({
      invitesHistory: (state) => state.invites.invitesHistory || [],
      responsible: (state) => state.invites.responsibleQuery,
      company: (state) => state.companies.currentCompany,
      isUnlimited: (state) => state.invites.allowUnlimitedInvites,
      history: (state) => state.invites.history,
      totalCount: (state) => state.invites.totalCount,
      resetInvitesHistory: (state) => state.invites.resetInvitesHistory,
    }),
    filter: {
      get() {
        return this.responsible;
      },
      set: debounce(async function(val) {
        await this.setResponsible({
          responsible: val,
        });
        this.updateList(true);
      }, 500),
    },
  },
  data: () => ({
    add,
    minus,
    itemComponent: ListItem,
    pageSize: 20,
    pageNumber: 1,
    mappedList: [],
    sorting: { date: 1 },
  }),
  watch: {
    resetInvitesHistory(value) {
      this.updateList(true);
    }
  },
  async mounted() {
    this.updateList(true);
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      setResponsible: "invites/setResponsible",
      sortHistory: "invites/sortHistory",
      getInvitesHistoryByOrg: "invites/getInvitesHistoryByOrg",
      invitesCount: "invites/getPossibleSurveyParticipants",
    }),
    triggerFetch() {
      if (this.pageNumber < Math.ceil(this.totalCount / this.pageSize)) {
        this.pageNumber++;
        this.updateList();
      }
    },
    async updateList(reset) {
      if (reset) {
        this.mappedList = [];
        this.pageNumber = 1;
      }
      await this.getInvitesHistoryByOrg({
        orgId: parseInt(this.$route.params.companyId, 10),
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        sorting: { date: this.sorting.date === -1 ? 1 : 2 },
      });

      this.mappedList = [...this.mappedList, ...this.invitesHistory];
    },

    removeInvites() {
      this.openModal({ modal: "removeInvitesModal" });
    },
    sort() {
      this.sorting["date"] = this.sorting["date"] * -1;
      this.updateList(true);
    },
    addInvites() {
      this.openModal({
        modal: "addInvitesModal",
      });
    },
  },
};
</script>
