<template>
  <div>
    <div class="grid grid-cols-6 text-md text-grey-dark-2 text-center py-2">
      <p>{{ source.date }}</p>
      <p :class="textColor">{{ showInvites }}</p>
      <p :class="textColor">{{ source.activity }}</p>
      <p class='text-grey-dark-1 font-semibold'>{{ source.remaining }}</p>
      <p>{{ source.responsibleName }}</p>
      <p>{{ source.role }}</p>
    </div>
    <div class='w-full h-line bg-grey-light-4'/>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
  },
  computed: {
    textColor() {
      return this.source.noOfInvites < 0 ? 'text-red' : 'text-green';
    },
    showInvites() {
      if (typeof this.source.noOfInvites === 'number' && this.source.noOfInvites > 0) {
        return `+ ${this.source.noOfInvites}`;
      }
      return this.source.noOfInvites;
    }
  },
};
</script>
